<template>
  <div class="main-alert browser-div">
    <img
      v-if="imagesrc"
      :src="imagesrc"
      alt="image"
      class="image"
      loading="lazy"
    />
    <p>{{ message }}</p>
  </div>
</template>

<script>
export default {
  props: ["message","imagesrc"]
};
</script>

<style lang="scss" scoped>
@import "~sass/modules/_variables.scss";
@import "~sass/modules/_fonts.scss";

.main-alert {
  color: $clr-white;
  font-family: $font-family;
  font-weight: $font-weight-medium;
  display: flex;
  justify-content: center;
  &.browser-div{
    background-color:$clr-color-black-4;
    padding:0px 10px;
  }
  &.third-party{
    background-color:$font-clr-red-2 !important;
    opacity:0.7;
    position:sticky;
    bottom:0;
    left:0;
    height:max-content;
    width:100%;
    z-index:3;
  }
}
.online-div {
  background-color: $clr-color-green;
}
.offline-div {
  background-color: $theme-primary;
}
.image {
  margin-right: 10px;
}
.offline-image {
  width: 20px;
}
p {
  padding: 5px 0px;
}
</style>